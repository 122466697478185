<template>
  <aside :class="{'closeBar':!isOpen}">
    <div class="sidebar-inner">

      <div class="nav-item menu" @click="isOpen=!isOpen">
        <div class="nav-icon">
          <img src="/icons/menu.svg" />
        </div>
        <div class="item" :class="{'hidden':!isOpen}">
          <span>Menu</span>
        </div>
      </div>

      <div :class="{ 'nav-item': true, 'nav-active': activeItem === 'Dashboard' }" @click="redirect('Dashboard')">
        <div class="nav-icon">
          <img src="/icons/dashboard.svg" />
        </div>
        <div class="item" :class="{'hidden':!isOpen}">
          <span>Dashboard</span>
        </div>
      </div>

      <div :class="{ 'nav-item': true, 'nav-active': activeItem === 'IdCard' }" id="item-id-card"
        @click="redirect('IdCard')">
        <div class="nav-icon">
          <img src="/icons/id-card.svg" />
        </div>
        <div class="item" :class="{'hidden':!isOpen}">
          <span>{{$t("sidebar.id-card")}}</span>
        </div>
      </div>

      <div :class="{ 'nav-item': true, 'nav-active': activeItem === 'Organizations' }" id="item-organizations"
        @click="redirect('Organizations')">
        <div class="nav-icon">
          <img src="/icons/organiszation.svg" />
        </div>
        <div class="item" :class="{'hidden':!isOpen}">
          <span>{{$t("sidebar.organization")}}</span>
        </div>
      </div>

      <div :class="{ 'nav-item': true, 'nav-active': activeItem === 'Users' }" id="item-users"
        @click="redirect('Users')">
        <div class="nav-icon">
          <img src="/icons/users.svg" />
        </div>
        <div class="item" :class="{'hidden':!isOpen}">
          <span>{{$t("sidebar.users")}}</span>
        </div>
      </div>

      <div :class="{ 'nav-item': true, 'nav-active': activeItem === 'Evaluations' }" id="item-evaluations"
        @click="redirect('Evaluations')">
        <div class="nav-icon">
          <img src="/icons/evaluation.svg" />
        </div>
        <div class="item" :class="{'hidden':!isOpen}">
          <span>{{$t("sidebar.evaluation")}}</span>
        </div>
      </div>

      <div :class="{ 'bottom-item': true, 'nav-item': true, 'nav-active': activeItem === 'Settings' }"
        id="item-settings" @click="redirect('CustomerAddress')">
        <div class="nav-icon" >
          <img src="/icons/settings.svg" />
        </div>
        <div class="item" :class="{'hidden':!isOpen}">
          <span>{{$t("sidebar.settings")}}</span>
        </div>

      </div>
    </div>

  </aside>

</template>
<script>
import Vue from 'vue';

export default Vue.extend({
  data(){
      return{
          isOpen: true,
          activeItem: "Dashboard",
          screenWidth: window.innerWidth,
          hideSettings:true
      }
  },

  // created() {
  //   if (this.$router.currentRoute.name === "CustomerAddress") {
  //     this.activeItem = "Settings"
  //   }
  // },

  computed: {
    user() {
      return this.$store.getters["auth/getUser"];
    },
  },

  methods:{
      redirect(name){
        if (!(this.$route.name == name)) {
          this.activeItem = name

          if (this.activeItem === "CustomerAddress") {
            this.activeItem = "Settings"
          }
          this.$router.push({ name: name});
        }

        if(this.screenWidth<600){
            this.isOpen=false;
        }else{
            this.isOpen=true;
        }
      }
  },

  async mounted() {
    if(this.screenWidth<900){
        this.isOpen=false;
    }

    if (
      (this.$router.currentRoute.name === "CustomerAddress") ||
      (this.$router.currentRoute.name === "BillingAddress") ||
      (this.$router.currentRoute.name === "DataAgreement") ||
      (this.$router.currentRoute.name === "IDCardLayout") ||
      (this.$router.currentRoute.name === "IdAdditionalFields") ||
      (this.$router.currentRoute.name === "Miscellaneous")
        
      ) {
      this.activeItem = "Settings"
    }

    if (this.$router.currentRoute.name === "IdCard") {
      this.activeItem = "IdCard"
    }

    if (this.$router.currentRoute.name === "Organizations") {
      this.activeItem = "Organizations"
    }

    if (this.$router.currentRoute.name === "Users") {
      this.activeItem = "Users"
    }

    if (this.$router.currentRoute.name === "Evaluations") {
      this.activeItem = "Evaluations"
    }

  },

  watch: {
    screenWidth(){
      if(this.screenWidth<900){
          this.isOpen=false;
      } else{
          this.isOpen=true;
      }
    }
  }
});
</script>