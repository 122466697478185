<template>
  <div class="home">
      <div class="centerx">
        <vs-popup classContent="popup-example user-popup"  :active.sync="showPopup" class="user-popup">
            <div class="popup-from expire mp-3">

                <div class="expire-container gray-border">
                    <div class="user-con-inner">
                        <h3>{{$t('password-change')}}</h3>   
                        <p>{{$t('user-pop-heading')}}</p>
                        <div class="list">
                            <p>- {{$t('user-popup1')}}</p>
                            <p>- {{$t('user-popup2')}}</p>
                            <p>- {{$t('user-popup3')}}</p>
                            <p>- {{$t('user-popup4')}}</p>
                        </div>
                        <vs-input type="password" class="inputx mb-2" style="width:260px!important" :label-placeholder="$t('password-new')" v-model="data.password_new"/>
                        <vs-input type="password" class="inputx mb-2" style="width:260px!important" :label-placeholder="$t('password-repeat')" v-model="data.password_repeat"/>
                        
                    </div>
                    <!-- Passwort neu -->
                </div>
                <div class="popup-bottom ">
                    <vs-button class="pop-btn" color="primary" type="filled" icon="done" @click="changePassword()">{{$t('save')}}</vs-button>
                    <vs-button class="pop-btn" color="primary" type="filled" icon="close" @click="showPopup=!showPopup">
                        {{$t("cancel")}}</vs-button>
                </div>
            </div>
        </vs-popup>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

import validators from '@/plugins/inputValidators'

export default Vue.extend({
    data(){
        return {
            showPopup: false,

            data: {
                email: '',
                password_new: '',
                password_repeat: '',
            }
        }
    },

    computed: {
        user() {
            return this.$store.getters['auth/getUser']
        }

    },

    methods:{
        open(){
            this.showPopup=true;
        },
        async changePassword() {
            let check = validators.isValidPassword(this.data.password_new, this.data.password_repeat)
            
            if (!(check.isValid)) {
                //  this.showError = true
                //  this.errorMessage = "asd"

                this.$vs.notify({
                    color: "danger",
                    position: "bottom-right",
                    title: "Passwort Fehler",
                    text: check.errorMessage
                })

                return 
            }

            // Reset Previous
            this.errorMessage = ""
            this.showError = false
            
            this.data.email = this.user.email

            await this.$store.dispatch('auth/changePassword', this.data)
            this.showPopup = false
            this.$router.push({ name: 'Login' });

        }

    }

});
</script>
<style >
.user-popup .vs-popup--title{
    opacity: 0!important;
}
.user-popup .vs-popup{
    max-width: 800px !important;
}
.user-popup .popup-bottom{
    padding-top: 2px;
    padding-bottom: 0px;
}
@media(max-width:450px){
 .user-popup .vs-popup{
    max-width: 400px !important;
 }   
}
</style>
