<template>
    <header>
      <div class="logo">
        <img src="/images/logo.svg">
      </div>
      <div class="show-sm" @click="toggle">
          <img src="/icons/more.svg" alt="" style="z-index:1000;width:30px;cursor:pointer;margin-right:30px">
      </div>
      <div class="h-items" v-if="showDropdown" style="border-radius:7px">
        <span class="h-item" style="border-radius: 7px 7px 0px 0px!important; color:#005B9B" @click="userPopUp()">
          <img src="/icons/user.svg" alt="" />
          {{user.username}}
        </span>
        <span class="h-item" style="border-radius:0px 0px 7px 7px!important;" @click="$router.push('/support')">
          <img src="/icons/question.svg" alt="" />
          <span class="show-sm" >support</span>
        </span>
        <span class="h-item" @click="logoutPopUp()" style="border-radius:0px 0px 7px 7px!important;">
          <img src="/icons/logout.svg" alt="" />
          <span class="show-sm" >Logout</span>
        </span>        
        <!-- <vs-switch v-model="switch1"/> -->
        <!-- <LanguageSelect/> -->
        <PopupPW ref="userPopup" />
        <PopupLogout ref="logoutPopup" />
      </div>

    </header>
</template>
<script>
import Vue from 'vue';
// import LanguageSelect from './components/LanguageSelect.vue'
import PopupPW from './components/PopupPW.vue'
import PopupLogout from './components/PopupLogout.vue'

export default Vue.extend({
    data(){
        return{
            showDropdown:false,
            screenWidth:window.innerWidth,
            switch1:true
        }
    },
    
    
    components:{
        // LanguageSelect,
        PopupPW,PopupLogout
    },
    
    
    methods:{
        toggle() {
            this.showDropdown=!this.showDropdown;
        },
        onResize() {
            this.screenWidth = window.innerWidth
        },
        // logout(){
        //     this.$router.push('/login')
        // },
        logoutPopUp(){
            const popup=this.$refs.logoutPopup ;
            popup.open();
        },
        userPopUp(){
            const popup=this.$refs.userPopup ;
            popup.open();
        }
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
        if(this.screenWidth<900){
              this.showDropdown=false;
            }else{
                this.showDropdown=true;
            }
        },
    beforeDestroy() { 
        window.removeEventListener('resize', this.onResize); 
    },


    watch:{
        screenWidth(){
            if(this.screenWidth<900){
                this.showDropdown=false;
            }else{
                this.showDropdown=true;
            }
        }
    },

    computed: {
        user() {
            return this.$store.getters['auth/getUser']
        }
    },

});
</script>
