<template>
  <div class="home">
    <div class="center">
      <vs-popup class="logout-popup" :active.sync="showPopup">
        <div class="popup-from expire mp-3">
          <div class="expire-container gray-border">
            <div class="logout-con-inner">
              <img src="/icons/dialog-question.svg" alt="" />
              <div style="margin-left: 20px;">
                <!-- <p>{{$t('logout-confirm')}}</p> -->
                <p>{{$t('Möchten Sie sich wirklich abmelden?')}}</p>
              </div>
            </div>
            <!-- Passwort neu -->
          </div>
          <div class="popup-bottom">
            <vs-button class="pop-btn" @click="logout()" color="primary" type="filled" icon="done"> {{$t('logout')}}</vs-button>
            <vs-button class="pop-btn" @click="showPopup=!showPopup" color="primary" type="filled" icon="close">{{$t("cancel")}}</vs-button>
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
  import Vue from "vue";
  export default Vue.extend({
    data() {
      return {
        showPopup: false,
      };
    },
    methods: {
      open() {
        this.showPopup = true;
      },
      async logout() {
        await this.$store.dispatch("auth/makeLogout");
        this.showPopup = !this.showPopup;
        this.$router.push({ name: "Login" });
      },
    },
  });
</script>
<style>
  .logout-popup .vs-popup--title {
    opacity: 0 !important;
  }
  .logout-popup .vs-popup {
    max-width: 600px !important;
  }
  .logout-popup .popup-bottom {
    padding-top: 2px;
    padding-bottom: 0px;
  }
  @media (max-width: 450px) {
    .logout-popup .vs-popup {
      max-width: 400px !important;
    }
  }
</style>
